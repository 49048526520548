export class Hero {
    constructor() {
        this.initVideoHero();
        this.initScrollToContent();
    }

    private initVideoHero(): void {
        const videoHero = document.querySelector('.hero-video');
        if (!videoHero) return;

        const iframe = videoHero.querySelector('iframe');
        if (!iframe) return;

        // Add loading class
        videoHero.classList.add('is-loading');

        // Remove loading class once video is loaded
        iframe.addEventListener('load', () => {
            videoHero.classList.remove('is-loading');
        });
    }

    private initScrollToContent(): void {
        const scrollToContentButton = document.querySelector('.scroll-to-content');
        if (!scrollToContentButton) return;

        scrollToContentButton.addEventListener('click', () => {
            const content = document.getElementById('content');
            if (!content) return;

            content.scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
}

// Initialize hero
new Hero();
