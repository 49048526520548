interface FlickityOptions {
    // Add any Flickity options you're using
    cellAlign?: string;
    contain?: boolean;
    wrapAround?: boolean;
}

interface FlickityInstance {
    select(index: number): void;
    next(): void;
    previous(): void;
}

declare const Flickity: {
    new (element: Element, options?: FlickityOptions): FlickityInstance;
};

document.addEventListener('DOMContentLoaded', function(): void {
    const roomTypeFilter = document.getElementById('room-type-filter') as HTMLSelectElement;
    if (!roomTypeFilter) return;

    roomTypeFilter.addEventListener('change', function(e: Event): void {
        const selectedRoomType = (e.target as HTMLSelectElement).value;
        const accommodations = document.querySelectorAll<HTMLElement>('.accommodation');

        accommodations.forEach((accommodation: HTMLElement): void => {
            const roomTypesList = accommodation.dataset.roomTypes || '';
            const roomTypes = roomTypesList.split(',');
            const shouldShow = !selectedRoomType || roomTypes.includes(selectedRoomType);
            
            if (shouldShow) {
                accommodation.style.display = 'flex';
                accommodation.style.opacity = '1';
                accommodation.style.transform = 'translateY(0)';
            } else {
                accommodation.style.opacity = '0';
                accommodation.style.transform = 'translateY(20px)';
                setTimeout(() => {
                    accommodation.style.display = 'none';
                }, 300); // Match the transition duration
            }
        });
    });

    // Initialize any existing filter value
    const event = new Event('change');
    roomTypeFilter.dispatchEvent(event);

    // Initialize Flickity
    const galleries = document.querySelectorAll('.js-flickity');
    galleries.forEach((gallery: Element) => {
        new Flickity(gallery);
    });
});