document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.site-header') as HTMLElement;
    const body = document.body;
    const adminBar = document.getElementById('wpadminbar');

    // Update header position remains if needed for admin bar adjustments
    const updateHeaderPosition = () => {
        if (adminBar) {
            header.style.top = `${adminBar.offsetHeight}px`;
            body.classList.add('admin-bar');
        }
    };
    updateHeaderPosition();
    window.addEventListener('resize', updateHeaderPosition);

    // Mobile menu toggle
    const mobileMenuToggle = document.querySelector('.mobile-menu-toggle') as HTMLElement;
    const closeMenu = document.querySelector('.close-menu') as HTMLElement;
    const offCanvasMenu = document.querySelector('.off-canvas-menu') as HTMLElement;
    const menuOverlay = document.querySelector('.menu-overlay') as HTMLElement;

    if (mobileMenuToggle && closeMenu && offCanvasMenu && menuOverlay) {
        mobileMenuToggle.addEventListener('click', () => {
            const expanded = mobileMenuToggle.getAttribute('aria-expanded') === 'true';
            mobileMenuToggle.setAttribute('aria-expanded', (!expanded).toString());
            offCanvasMenu.classList.add('active');
            offCanvasMenu.setAttribute('aria-hidden', 'false');
            menuOverlay.classList.add('active');
            menuOverlay.setAttribute('aria-hidden', 'false');
        });

        closeMenu.addEventListener('click', () => {
            mobileMenuToggle.setAttribute('aria-expanded', 'false');
            offCanvasMenu.classList.remove('active');
            offCanvasMenu.setAttribute('aria-hidden', 'true');
            menuOverlay.classList.remove('active');
            menuOverlay.setAttribute('aria-hidden', 'true');
        });

        menuOverlay.addEventListener('click', () => {
            mobileMenuToggle.setAttribute('aria-expanded', 'false');
            offCanvasMenu.classList.remove('active');
            offCanvasMenu.setAttribute('aria-hidden', 'true');
            menuOverlay.classList.remove('active');
            menuOverlay.setAttribute('aria-hidden', 'true');
        });
    }
});
